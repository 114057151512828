@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.glassCard {
		background: rgba(100, 24, 231, 0.2);
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(11px);
		-webkit-backdrop-filter: blur(11px);
		border-radius: 10px;
	}
}
